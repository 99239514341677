import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const API_PREFIX = '/labinv/api';

export const fetchPDFs = createAsyncThunk(
  'pdfs/fetchPDFs',
  async () => {
    const response = await fetch(`${API_PREFIX}/pdfs`);
    if (!response.ok) throw new Error('Failed to fetch PDFs');
    return response.json();
  }
);

export const uploadPDF = createAsyncThunk(
  'pdfs/uploadPDF',
  async (formData) => {
    const response = await fetch(`${API_PREFIX}/upload-pdf`, {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) throw new Error('Failed to upload PDF');
    return response.json();
  }
);

export const updatePDFStatus = createAsyncThunk(
  'pdfs/updatePDFStatus',
  async ({ id, status }) => {
    const response = await fetch(`${API_PREFIX}/pdfs/${id}/status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status }),
    });
    if (!response.ok) throw new Error('Failed to update PDF status');
    return response.json();
  }
);

export const deletePDF = createAsyncThunk(
  'pdfs/deletePDF',
  async (pdfId) => {
    const response = await fetch(`${API_PREFIX}/pdfs/${pdfId}`, {
      method: 'DELETE',
    });
    if (!response.ok) throw new Error('Failed to delete PDF');
    return pdfId;
  }
);

export const fetchPDFData = createAsyncThunk(
  'pdfs/fetchPDFData',
  async (pdfId) => {
    const response = await fetch(`${API_PREFIX}/pdfs/${pdfId}/data`);
    if (!response.ok) throw new Error('Failed to fetch PDF data');
    return response.json();
  }
);

export const exportPDFData = createAsyncThunk(
  'pdfs/exportPDFData',
  async ({ id, format }) => {
    const response = await fetch(`${API_PREFIX}/pdfs/${id}/export?format=${format}`);
    if (!response.ok) throw new Error('Failed to export PDF data');
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `pdf-export.${format}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
    return { id, format };
  }
);

const initialState = {
  documents: [],
  extractedData: {},
  status: 'idle',
  error: null,
  currentPDF: null,
};

const pdfSlice = createSlice({
  name: 'pdfs',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setCurrentPDF: (state, action) => {
      state.currentPDF = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPDFs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPDFs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documents = action.payload;
        state.error = null;
      })
      .addCase(fetchPDFs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(uploadPDF.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadPDF.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documents.push(action.payload);
        state.error = null;
      })
      .addCase(uploadPDF.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deletePDF.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePDF.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documents = state.documents.filter(doc => doc.id !== action.payload);
        state.error = null;
      })
      .addCase(deletePDF.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPDFData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPDFData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.extractedData[action.payload.id] = action.payload.data;
        state.error = null;
      })
      .addCase(fetchPDFData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(exportPDFData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(exportPDFData.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(exportPDFData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updatePDFStatus.fulfilled, (state, action) => {
        const index = state.documents.findIndex(doc => doc.id === action.payload.id);
        if (index !== -1) {
          state.documents[index] = action.payload;
        }
      });
  },
});

export const { clearError, setCurrentPDF } = pdfSlice.actions;

export const selectAllPDFs = (state) => state.pdfs.documents;
export const selectPDFById = (state, pdfId) => 
  state.pdfs.documents.find(doc => doc.id === pdfId);
export const selectPDFStatus = (state) => state.pdfs.status;
export const selectPDFError = (state) => state.pdfs.error;
export const selectExtractedData = (state, pdfId) => 
  state.pdfs.extractedData[pdfId];
export const selectCurrentPDF = (state) => state.pdfs.currentPDF;

export default pdfSlice.reducer;