import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error("Video playback failed:", error);
        console.log("Video element:", videoRef.current);
        console.log("Video source:", videoRef.current?.currentSrc);
      });
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <nav className="bg-gray-800 text-white p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <img src="/labinv/static/vilogo.png" alt="Logo" className="h-8 w-8 mr-2" />
            <span className="text-xl font-bold">Vector Intelligence</span>
          </div>
          <Link to="/login" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Login
          </Link>
        </div>
      </nav>

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="text-center max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-6">
            Welcome to Vector Intelligence
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Securely manage and organize your organization's PDF documents
          </p>
          <div className="rounded-lg overflow-hidden shadow-xl mb-8">
            <video
              ref={videoRef}
              className="w-full"
              controls
              autoPlay
              muted
              loop
              playsInline
              onError={(e) => console.error("Video error:", e)}
            >
              <source src="/labinv/static/VectorVideo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Vector Intelligence. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;