// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import LoginForm from './LoginForm';
import PDFList from './PDFList';
import ManageUsers from './ManageUsers';
import SidebarLayout from './SidebarLayout';
import ParentAdminDashboard from './ParentAdminDashboard';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { checkSession } from '../slices/authSlice';
import { fetchPDFs } from '../slices/pdfSlice';
import ResetPasswordForm from './ResetPasswordForm';

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(fetchPDFs());
    }
  }, [auth.isAuthenticated, dispatch]);

  return (
    <Router basename="/labinv">
      <Routes>
      <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
        {!auth.isAuthenticated ? (
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="*" element={<Navigate replace to="/" />} />
            
          </>
        ) : (
          <Route element={<SidebarLayout />}>
            <Route index element={<Navigate to="/pdfs" replace />} />
            <Route path="pdfs" element={<PDFList />} />
            <Route 
              path="manage-users" 
              element={
                ['admin', 'parent_admin'].includes(auth.user?.role) ? 
                <ManageUsers /> : 
                <Navigate to="/pdfs" />
              }
            />
            <Route 
              path="parent-admin" 
              element={
                auth.user?.role === 'parent_admin' ? 
                <ParentAdminDashboard /> : 
                <Navigate to="/pdfs" />
              }
            />
            <Route path="*" element={<Navigate replace to="/pdfs" />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
}

export default App;