import React, { useState } from 'react';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../slices/authSlice';
import { Menu, X } from 'lucide-react';

const SidebarLayout = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Default to true for desktop
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Mobile Header */}
      <div className="lg:hidden fixed top-0 w-full bg-gray-800 z-50 px-4 py-2 flex items-center justify-between">
        <button onClick={toggleSidebar} className="text-white p-2">
          {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
        <div className="text-white text-lg font-bold">
          {auth.user?.organization?.name || 'Vector Intelligence'}
        </div>
      </div>

      {/* Sidebar Overlay */}
      {isSidebarOpen && (
        <div 
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleSidebar}
        />
      )}

      {/* Sidebar */}
      <aside className={`
        fixed lg:static w-[80%] max-w-[256px] md:w-64 h-full bg-gray-800 text-white z-50
        transform transition-transform duration-300 ease-in-out
        ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-[-256px]'}
      `}>
        <div className="p-4 flex justify-between items-center">
          <img src="/labinv/static/vilogo.png" alt="Logo" className="h-12 w-12" />
          {/* Desktop Exit Button */}
          <button 
            onClick={toggleSidebar}
            className="hidden lg:flex text-white hover:text-gray-300 p-1"
          >
            <X size={20} />
          </button>
        </div>
        
        <nav className="mt-4 flex flex-col h-[calc(100%-theme(spacing.32))]">
          <div className="flex-1">
            <Link 
              to="/pdfs" 
              className="block px-4 py-2 hover:bg-gray-700 active:bg-gray-600"
              onClick={() => setIsSidebarOpen(false)}
            >
              View Documents
            </Link>
            {auth.user?.role === 'parent_admin' && (
              <Link 
                to="/parent-admin" 
                className="block px-4 py-2 hover:bg-gray-700 active:bg-gray-600"
                onClick={() => setIsSidebarOpen(false)}
              >
                Organization Management
              </Link>
            )}
            {['admin', 'parent_admin'].includes(auth.user?.role) && (
              <Link 
                to="/manage-users" 
                className="block px-4 py-2 hover:bg-gray-700 active:bg-gray-600"
                onClick={() => setIsSidebarOpen(false)}
              >
                Manage Users
              </Link>
            )}
          </div>
          
          {/* Mobile Logout Button */}
          <div className="lg:hidden p-4 border-t border-gray-700">
            <button
              onClick={handleLogout}
              className="w-full text-left px-4 py-2 text-white hover:bg-gray-700 active:bg-gray-600"
            >
              Logout
            </button>
          </div>
        </nav>
      </aside>

      {/* Show/Hide Sidebar Button for Desktop */}
      <button
        onClick={toggleSidebar}
        className={`hidden lg:flex fixed z-50 left-0 top-4 text-gray-800 hover:text-gray-600 p-2 transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? 'translate-x-0 opacity-0' : 'translate-x-4 opacity-100'
        }`}
      >
        <Menu size={24} />
      </button>

      {/* Main Content */}
      <div className="flex-1 flex flex-col min-h-screen overflow-hidden">
        {/* Desktop Header */}
        <header className="hidden lg:block bg-white shadow">
          <div className="px-4 py-6 flex justify-between items-center">
            <h2 className="text-xl font-semibold">
              {auth.user?.organization?.name || 'Vector Intelligence'}
            </h2>
            
            <div className="relative">
              <button 
                onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                className="flex items-center space-x-2 hover:bg-gray-100 px-3 py-2 rounded"
              >
                <span>Hi {auth.user?.username}</span>
                <span>{isUserMenuOpen ? '▼' : '▶'}</span>
              </button>

              {isUserMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded shadow-lg">
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 mt-14 lg:mt-0">
          <div className="container mx-auto px-6 py-8">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default SidebarLayout;