import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { uploadPDF, deletePDF, updatePDFStatus } from '../slices/pdfSlice';


const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg p-6 w-[500px] max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

function PDFList() {
  const dispatch = useDispatch();
  const pdfs = useSelector(state => state.pdfs.documents);
  const user = useSelector(state => state.auth.user);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'filename',
    direction: 'asc'
  });
  const [formData, setFormData] = useState({
    expected_order_date: '',
    project_location: '',
    quote_needed_by: '',
    is_domestic_steel: null,
    status: 'in_progress',
  });

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    });
  };

  const sortedPDFs = [...pdfs].sort((a, b) => {
    let aVal = a[sortConfig.key];
    let bVal = b[sortConfig.key];

    if (sortConfig.key === 'status') {
      aVal = a.status || 'in_progress';
      bVal = b.status || 'in_progress';
    }
    else if (sortConfig.key === 'organization') {
      aVal = a.organization?.name || '';
      bVal = b.organization?.name || '';
    } else if (sortConfig.key.startsWith('questionnaire.')) {
      const nestedKey = sortConfig.key.split('.')[1];
      aVal = a.questionnaire?.[nestedKey] || '';
      bVal = b.questionnaire?.[nestedKey] || '';
    }

    if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const filteredPDFs = sortedPDFs.filter(pdf =>
    pdf.filename.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (pdf.organization?.name || '').toLowerCase().includes(searchQuery.toLowerCase())
  );

  const SortIndicator = ({ columnKey }) => {
    if (sortConfig.key !== columnKey) return null;
    return (
      <span className="ml-1">
        {sortConfig.direction === 'asc' ? '↑' : '↓'}
      </span>
    );
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsModalOpen(true);
      // Reset the input value so the same file can be selected again
      event.target.value = '';
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    setFormData({
      expected_order_date: '',
      project_location: '',
      quote_needed_by: '',
      is_domestic_steel: null
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) return;

    const submitData = new FormData();
    submitData.append('file', selectedFile);
    Object.entries(formData).forEach(([key, value]) => {
      submitData.append(key, value);
    });

    dispatch(uploadPDF(submitData));
    handleModalClose();
  };

  const handleDelete = (pdfId) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      dispatch(deletePDF(pdfId));
    }
  };

  const handleStatusChange = async (pdfId, currentStatus) => {
    const newStatus = currentStatus === 'completed' ? 'in_progress' : 'completed';
    try {
      await dispatch(updatePDFStatus({ id: pdfId, status: newStatus })).unwrap();
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };


  return (
    <div className="p-6">
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-bold">PDF Documents</h1>
        <div className="flex gap-4">
          <input
            type="text"
            placeholder="Search documents..."
            className="input input-bordered w-64"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {user.role === 'admin' && (
            <>
              <input
                type="file"
                accept=".pdf"
                className="hidden"
                id="pdf-upload"
                onChange={handleFileSelect}
              />
              <label htmlFor="pdf-upload" className="btn btn-primary">
                Upload PDF
              </label>
            </>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Additional Information Required</h2>
          <button
            onClick={handleModalClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Expected Order Date *</label>
            <input
              type="date"
              required
              className="w-full p-2 border rounded"
              value={formData.expected_order_date}
              onChange={e => setFormData({ ...formData, expected_order_date: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Project Location *</label>
            <input
              type="text"
              required
              placeholder="Enter project location"
              className="w-full p-2 border rounded"
              value={formData.project_location}
              onChange={e => setFormData({ ...formData, project_location: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Quote Needed By *</label>
            <input
              type="date"
              required
              className="w-full p-2 border rounded"
              value={formData.quote_needed_by}
              onChange={e => setFormData({ ...formData, quote_needed_by: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Domestic Steel Required? *</label>
            <div className="flex gap-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="domestic_steel"
                  className="mr-2"
                  checked={formData.is_domestic_steel === true}
                  onChange={() => setFormData({ ...formData, is_domestic_steel: true })}
                  required
                />
                Yes
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="domestic_steel"
                  className="mr-2"
                  checked={formData.is_domestic_steel === false}
                  onChange={() => setFormData({ ...formData, is_domestic_steel: false })}
                />
                No
              </label>
            </div>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              className="px-4 py-2 border rounded hover:bg-gray-100"
              onClick={handleModalClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Upload
            </button>
          </div>
        </form>
      </Modal>

      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th
                onClick={() => handleSort('filename')}
                className="cursor-pointer hover:bg-gray-100"
              >
                Filename <SortIndicator columnKey="filename" />
              </th>
              <th
                onClick={() => handleSort('organization')}
                className="cursor-pointer hover:bg-gray-100"
              >
                Organization <SortIndicator columnKey="organization" />
              </th>
              <th
                onClick={() => handleSort('upload_date')}
                className="cursor-pointer hover:bg-gray-100"
              >
                Upload Date <SortIndicator columnKey="upload_date" />
              </th>
              <th
                onClick={() => handleSort('questionnaire.project_location')}
                className="cursor-pointer hover:bg-gray-100"
              >
                Project Location <SortIndicator columnKey="questionnaire.project_location" />
              </th>
              <th
                onClick={() => handleSort('questionnaire.expected_order_date')}
                className="cursor-pointer hover:bg-gray-100"
              >
                Order Date <SortIndicator columnKey="questionnaire.expected_order_date" />
              </th>
              <th
                onClick={() => handleSort('questionnaire.quote_needed_by')}
                className="cursor-pointer hover:bg-gray-100"
              >
                Quote Due <SortIndicator columnKey="questionnaire.quote_needed_by" />
              </th>
              <th
                onClick={() => handleSort('questionnaire.is_domestic_steel')}
                className="cursor-pointer hover:bg-gray-100"
              >
                Domestic Steel <SortIndicator columnKey="questionnaire.is_domestic_steel" />
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPDFs.map(pdf => (
              <tr key={pdf.id}>
                <td>
                  <Link to={`pdfs/${pdf.id}`} className="link link-primary">
                    {pdf.filename}
                  </Link>
                </td>
                <td>{pdf.organization?.name}</td>
                <td>{new Date(pdf.upload_date).toLocaleDateString()}</td>
                <td>{pdf.questionnaire?.project_location || '-'}</td>
                <td>{pdf.questionnaire?.expected_order_date ? new Date(pdf.questionnaire.expected_order_date).toLocaleDateString() : '-'}</td>
                <td>{pdf.questionnaire?.quote_needed_by ? new Date(pdf.questionnaire.quote_needed_by).toLocaleDateString() : '-'}</td>
                <td>{pdf.questionnaire?.is_domestic_steel ? 'Yes' : 'No'}</td>
                <td>
                  {user.role === 'admin' ? (
                    <button
                      onClick={() => handleStatusChange(pdf.id, pdf.status)}
                      className={`btn btn-sm ${pdf.status === 'completed' ? 'btn-success' : 'btn-warning'
                        }`}
                    >
                      {pdf.status === 'completed' ? 'Completed' : 'In Progress'}
                    </button>
                  ) : (
                    <div className={`badge ${pdf.status === 'completed' ? 'badge-success' : 'badge-warning'
                      }`}>
                      {pdf.status === 'completed' ? 'Completed' : 'In Progress'}
                    </div>
                  )}
                </td>
                <td className="flex gap-2">
                  <Link to={`pdfs/${pdf.id}`} className="btn btn-sm btn-info">
                    View
                  </Link>
                  {user.role === 'admin' && (
                    <button
                      className="btn btn-sm btn-error"
                      onClick={() => handleDelete(pdf.id)}
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PDFList;